import Slider from "react-slick";
import React, { Component }  from 'react';


function ClientSlider() {
	let publicUrl = process.env.PUBLIC_URL+'/'
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
		autoplaySpeed: 100,
        speed: 1200,
        arrows: false,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
				
			  }
			},	
			{
			  breakpoint: 767,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				
			  }
			},
		]
		
    };
    return (
        <>
          <div className="row justify-content-center">
				      <div className="col-xl-6 col-lg-7">
				        <div className="section-title text-center">
				          <h5 className="sub-title double-line">COMPANIES</h5>
				          <h6 className="title">We are backed by IT &amp; Engineering Professionals who worked in the following companies</h6>
				        </div>
				      </div>
				    </div>


			<Slider {...settings}>
			
			<div className="item">
					<div className="clients-logo">
						<img className="logo-main" src={publicUrl+"assets/img/institute/tcs2.png"} alt="img"  />
						<img className="logo-hover" src={publicUrl+"assets/img/institute/tcs2.png"} alt="img"  />

					</div>
				</div>
				<div className="item">
					<div className="clients-logo">
						<img className="logo-main" src={publicUrl+"assets/img/institute/Info2.png"} alt="img" />
						<img className="logo-hover" src={publicUrl+"assets/img/institute/Info2.png"} alt="img" />

					</div>
				</div>
				<div className="item">
					<div className="clients-logo">
						<img className="logo-main" src={publicUrl+"assets/img/institute/cts.png"} alt="img"  />
						<img className="logo-hover" src={publicUrl+"assets/img/institute/cts.png"} alt="img"  />

					</div>
				</div>
				<div className="item">
					<div className="clients-logo">
						<img className="logo-main" src={publicUrl+"assets/img/institute/apple2.png"} alt="img" />
						<img className="logo-hover" src={publicUrl+"assets/img/institute/apple2.png"} alt="img" />

					</div>
				</div>
				<div className="item">
					<div className="clients-logo">
						<img className="logo-main" src={publicUrl+"assets/img/hp2.png"} alt="img" />
						<img className="logo-hover" src={publicUrl+"assets/img/hp2.png"} alt="img" />

					</div>
				</div>
				<div className="item">
					<div className="clients-logo">
						<img className="logo-main" src={publicUrl+"assets/img/institute/gm2.png"} alt="img" />
						<img className="logo-hover" src={publicUrl+"assets/img/institute/gm2.png"} alt="img" />

					</div>
				</div>
				
				
				<div className="item">
					<div className="clients-logo">
						<img className="logo-main" src={publicUrl+"assets/img/institute/Amex2.png"} alt="img" />
						<img className="logo-hover" src={publicUrl+"assets/img/institute/Amex2.png"} alt="img" />

					</div>
				</div>
				
				
				
			
				
				
				
			
			
				
			
			
			</Slider>
			
        </>
    );
}

export default ClientSlider;
