import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

           let SectionClass = this.props.sectionclass ? this.props.sectionclass : ''

    return <div className={"about-area pd-top-120 go-top "+SectionClass}>
			  <div className="container">
			    <div className="about-area-inner">
			      <div className="row">
			        <div className="col-lg-6 col-md-2">
			          <div className="about-thumb-wrap after-shape" style={{backgroundImage: 'url("'+publicUrl+'assets/img/careers.png")'}}>
			          </div>
			        </div>
			        <div className="col-lg-6">
			          <div className="about-inner-wrap">  
			            <div className="section-title mb-0">
			              <h6 className="sub-title right-line">ABOUT US</h6>
			              <h2 className="title">Software solutions for the future</h2>
						  <span>Incliff Technologies was founded by a group of Software Engineers who have more than decades of experiences in the IT field. They bring a huge wealth of knowledge and expertise to the current software needs of various businesses.</span>
			        <p className="mt-3"> Throughout these years, they have accumulated knowledge and gained valuable experiences while working in a plethora of software technologies.</p>
			            
						  <div className="row">
			                <div className="col-sm-6">
			                  <ul className="single-list-wrap">
			                    <li className="single-list-inner style-check-box">
			                      <i className="fa fa-check" /> Finance
			                    </li>
			                    <li className="single-list-inner style-check-box">
			                      <i className="fa fa-check" /> Logistics
			                    </li>
			                    <li className="single-list-inner style-check-box">
			                      <i className="fa fa-check" /> Healthcare 
			                    </li>
			                  </ul>
			                </div>
			                <div className="col-sm-6">
			                  <ul className="single-list-wrap">
			                    <li className="single-list-inner style-check-box">
			                      <i className="fa fa-check" /> Automobile
			                    </li>
			                    <li className="single-list-inner style-check-box">
			                      <i className="fa fa-check" /> Supply Chain Management
			                    </li>
			                    <li className="single-list-inner style-check-box">
			                      <i className="fa fa-check" />Tailor Software
			                    </li>
			                  </ul>
			                </div>
							
			              </div>
						  
			             
						  {/* <Link className="btn btn-border-black " to="/about">Read More</Link> */}
			           
					    </div>
						
			          </div>
					  
			        </div>
					<div className="details-inner mt-4 pt-xl-3">
			        <span>The thrust of Incliff Technologies is to provide current businesses with tailored software solutions that will make them to run efficiently and largely make them future ready. </span>
					
			        <p className="mt-3"> 

At Incliff Technologies, we provide <b>EFFICIENT, COST EFFECTIVE, FUTURE PROOF, SCALABLE </b> solutions with <b>TRANSPARENT </b> processes. We rightly concur, Incliff Technologies is the right solution for your software needs. 

</p>
			      </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV2