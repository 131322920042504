import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FounderDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="main-blog-area pd-top-120 pd-bottom-90">
			  <div className="container">
			    <div className="team-details-page">
			      <div className="row">
			        <div className="col-lg-4">
			          <div className="thumb">
			            <img src={publicUrl+"assets/img/Promod.png"} alt="img" />
			          </div>
			        </div>
			        <div className="col-lg-7 align-self-center mt-5 mt-lg-0">
			          <div className="details">
			            <h3>Promod</h3>
			            <span className="designation">Co-founder</span>
			            <ul className="social-media style-base pt-2 mb-4">
			              <li>
			                <a href="https://www.linkedin.com/in/promod-manickam-76a31b58/"><i className="fa fa-facebook" aria-hidden="true" /></a>
			              </li>
			              <li>
			                <a href="https://www.linkedin.com/in/promod-manickam-76a31b58/"><i className="fa fa-twitter" aria-hidden="true" /></a>
			              </li>
			             
			             
			              <li>
			                <a href="https://www.linkedin.com/in/promod-manickam-76a31b58/ "><i className="fa fa-linkedin" aria-hidden="true" /></a>
			              </li>
			            </ul>
			            <span>Promod has around 18 years of experience in designing and building enterprise applications using Java, J2EE and big data technologies in India and USA.</span>
			            <p className="mt-4">He has worked in the companies such as Apple, HP, Amex, J&J. He is currently engaged with General Motors in Atlanta, USA as a Senior System Architect to design and build micro services in IoT. </p>
			          </div>
			        </div>
			      </div>

			    </div>
			
			  </div>
			</div>

        }
}

export default FounderDetails