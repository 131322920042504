import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Contact extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div>
			  <div className="contact-list pd-top-120 pd-bottom-90">
			    <div className="container">
			    
			    </div>
			  </div>
			  {/* counter area start */}
			  <div className="counter-area pd-bottom-120">
			    <div className="container">
			      <div className="row">
			        <div className="col-lg-4">
			          <div className="section-title mb-0">
			            <h6 className="sub-title right-line">Join With Us</h6>
			            <h2 className="title">path of opportunities</h2>
			            <p className="content pb-3">The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, </p>
			         
			          </div>
			        </div>
			        <div className="col-lg-8 mt-5 mt-lg-0">
			          <form className="contact-form-inner  mt-5 mt-md-0">
			            <div className="row">
			              <div className="col-lg-6">
			                <div className="single-input-inner style-bg-border">
			                  <input type="text" placeholder="Name" />
			                </div>
			              </div>
			              <div className="col-lg-6">
			                <div className="single-input-inner style-bg-border">
			                  <input type="text" placeholder="Email" />
			                </div>
			              </div>
			              <div className="col-6">
			                <div className="single-input-inner style-bg-border">
			                  <input type="text" placeholder="Phone" />
			                </div>
			              </div>
			              <div className="col-6">
			                <div className="single-input-inner style-bg-border">
			                  <input type="text" placeholder="Experience" />
			                </div>
			              </div>
			              <div className="col-12">
			                <div className="single-input-inner style-bg-border">
			                  <textarea placeholder="Why do you want to join with us" defaultValue={""} />
			                </div>
			              </div>
			              <div className="col-12">
			                <button className="btn btn-base">Send Message</button>
			              </div>
			            </div>
			          </form>
			        </div>
			      </div>
			    </div>
			  </div>
			  {/* counter area end */}
			  {/* contact area start */}
			 
			</div>

        }
}

export default Contact