import React from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import About from './section-components/about-v2';
import FunFact from './section-components/fun-fact';
import FounderDetails from './section-components/about-founder';
import Footer from './global-components/footer';
import Slider from './section-components/ClientSlider';
const AboutPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="About Us"  />
        <About sectionclass="pd-bottom-60" />
         <FounderDetails/>
         <br />
  
       
         <Slider />
       {/* <Event />  */}
       <br />
         <br />
         <br />
         <br />
      
        <Footer />
    </div>
}

export default AboutPage

