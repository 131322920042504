import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';


class ContactPage extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div>
			  <div className="contact-list pd-top-90 pd-bottom-90">
<div className="pricing-area pd-top-150 pd-bottom-100 text-center" style={{backgroundImage: 'url("'+publicUrl+'assets/img/testimonial/bg.png")'}}> 
			  
			    <div className="container">
				
			      <div className="row justify-content-center">
				 
			        <div className="col-lg-6" >
			          <div className="contact-list-inner">
			            <div className="media">
			              <div className="media-left">
			                <img style={{width: '90%',}} src={publicUrl+"assets/img/icon/35.png"} alt="img" />
			              </div>
			              <div className="media-body align-self-center">
			                <h5>Restaurant Management <br/>(Complete Solution) </h5>
							
			               
			                <p>This package offers a restaurant a seamless function to run, and it involves all the tools necessary for this business to run efficiently and in a cost-effective way.  <br /><br/></p>
							{/* <span> <div className="details"> 
			           <h6><Link to="/course-details">Read more &nbsp;&nbsp;<i  className="fa fa-arrow-right" /></Link></h6> 
			          
			          </div> </span> */}
			              </div>
						 
			            </div>
						
			          </div>
					  
			        </div>
			        
					<div className="col-lg-6">
			          <div className="contact-list-inner">
			            <div className="media">
			              <div className="media-left">
			                <img style={{width: '90%',}} src={publicUrl+"assets/img/icon/29.png"} alt="img" />
			              </div>
			              <div className="media-body align-self-center">
			                <h5>Supermarket Management <br/> (Aggregator) </h5>
						
			                
			                <p>This package enables the business to connect with its customers and run this business efficiently with its tailor made and easy to use software.  <br /><br/></p>
							{/* <span> <div className="details"> 
			          <h6><Link to="/course-details">Read more &nbsp;&nbsp;<i  className="fa fa-arrow-right" /></Link></h6>  
			          
			          </div> </span> */}
						  </div>
			            </div>
			          </div>
			        </div>
				
			
					
			        
			      </div>
			    </div>
			  </div>
			  {/* counter area start */}
			  {/* <div className="counter-area pd-bottom-120">
			    <div className="container">
			      <div className="row">
			        <div className="col-lg-4">
			          <div className="section-title mb-0">
			            <h6 className="sub-title right-line">Get in touch</h6>
			            <h2 className="title">Write Us a Message</h2>
			            <p className="content pb-3">The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, </p>
			            <ul className="social-media style-base pt-3">
			              <li>
			                <a href="#"><i className="fa fa-facebook" aria-hidden="true" /></a>
			              </li>
			              <li>
			                <a href="#"><i className="fa fa-twitter" aria-hidden="true" /></a>
			              </li>
			              <li>
			                <a href="#"><i className="fa fa-instagram" aria-hidden="true" /></a>
			              </li>
			              <li>
			                <a href="#"><i className="fa fa-pinterest" aria-hidden="true" /></a>
			              </li>
			              <li>
			                <a href="#"><i className="fa fa-linkedin" aria-hidden="true" /></a>
			              </li>
			            </ul>
			          </div>
			        </div>
			        <div className="col-lg-8 mt-5 mt-lg-0">
			          <form className="contact-form-inner  mt-5 mt-md-0">
			            <div className="row">
			              <div className="col-lg-6">
			                <div className="single-input-inner style-bg-border">
			                  <input type="text" placeholder="First Name" />
			                </div>
			              </div>
			              <div className="col-lg-6">
			                <div className="single-input-inner style-bg-border">
			                  <input type="text" placeholder="Last Name" />
			                </div>
			              </div>
			              <div className="col-6">
			                <div className="single-input-inner style-bg-border">
			                  <input type="text" placeholder="Email" />
			                </div>
			              </div>
			              <div className="col-6">
			                <div className="single-input-inner style-bg-border">
			                  <input type="text" placeholder="Subject" />
			                </div>
			              </div>
			              <div className="col-12">
			                <div className="single-input-inner style-bg-border">
			                  <textarea placeholder="Message" defaultValue={""} />
			                </div>
			              </div>
			              <div className="col-12">
			                <button className="btn btn-base">Send Message</button>
			              </div>
			            </div>
			          </form>
			        </div>
			      </div>
			    </div>
			  </div> */}
			  {/* counter area end */}
			  {/* contact area start */}
			  {/* <div className="contact-g-map">
			    <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d29208.601361499546!2d90.3598076!3d23.7803374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1589109092857!5m2!1sen!2sbd" />
			  </div> */}
			  </div>
			</div>

        }
}

export default ContactPage