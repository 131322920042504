import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
function Banner() {
	let publicUrl = process.env.PUBLIC_URL+'/'
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
		autoplaySpeed: 1000,
        speed: 1800,
        arrows: false,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				
			  }
			},	
			{
			  breakpoint: 767,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				
			  }
			},
		]
		
    };

 

    return  (
		<>
		<Slider {...settings}>
	<div className="banner-area banner-area-1 bg-gray go-top">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-5 col-md-8 order-lg-12 align-self-center">
			        <div className="thumb b-animate-thumb">
			          <img src={publicUrl+"assets/img/inclif27.png"} alt="img" />
			        </div>
			      </div>
			      <div className="col-lg-7 order-lg-1 align-self-center">
			        <div className="banner-inner text-center text-lg-left mt-5 mt-lg-0">
			          <h6 className="b-animate-1 sub-title">DISCOVER RESEARCH</h6>
			          <h2 className="b-animate-2 title">Empowering Your  Ideas</h2>
			     
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="banner-area banner-area-1 bg-gray go-top">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-5 col-md-8 order-lg-12 align-self-center">
			        <div className="thumb b-animate-thumb">
			          <img src={publicUrl+"assets/img/ns11.png"} alt="img" />
			        </div>
			      </div>
			      <div className="col-lg-7 order-lg-1 align-self-center">
			        <div className="banner-inner text-center text-lg-left mt-5 mt-lg-0">
			          <h6 className="b-animate-1 sub-title">DISCOVER RESEARCH</h6>
			          <h2 className="b-animate-2 title">Software solutions for the future</h2>
			          {/* <Link className="btn btn-base b-animate-3 mr-sm-3 mr-2" to="/">Get A Quote</Link>
			          <Link className="btn btn-border-black b-animate-3" to="/">Read More</Link> */}
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="banner-area banner-area-1 bg-gray go-top">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-5 col-md-8 order-lg-12 align-self-center">
			        <div className="thumb b-animate-thumb">
			          <img src={publicUrl+"assets/img/ns12.png"} alt="img" />
			        </div>
			      </div>
			      <div className="col-lg-7 order-lg-1 align-self-center">
			        <div className="banner-inner text-center text-lg-left mt-5 mt-lg-0">
			          <h6 className="b-animate-1 sub-title">DISCOVER RESEARCH</h6>
			          <h2 className="b-animate-2 title">Training the Emergent Workforce</h2>
			      
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
</Slider>
</>
 )
}

export default Banner