import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="about-area pd-top-80">
			  <div className="container">
			    <div className="about-area-inner">
			      <div className="row">
			        <div className="col-lg-6 align-self-center">
			          <img src={publicUrl+"assets/img/about/5.png"} alt="img" />
			        </div>
			        <div className="col-lg-6 align-self-center">
			          <div className="about-inner-wrap">  
			            <div className="section-title mb-0">
			              <h6 className="sub-title style-btn">ABOUT US</h6>
			              <h3 className="title">Software solutions for the future</h3>
			              <p className="content">Incliff Technologies was founded by group of software technology experts who have decades of experiences in the IT field. </p>
						 <p className="content">Together, we have expertise in software solutions for Finance, Logistics, Healthcare, Automobile and Supply Chain Management.</p>
						 <p className="content">Incliff Technologies is the right answer for all your software needs. </p>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV5