import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Pricing extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="pricing-area pd-top-120 pd-bottom-90">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-4 col-md-6">
			        <div className="single-pricing-inner-wrap">
			          <div className="single-pricing-inner">
			           {/* <h2 className="title">USA</h2> */}
			            <div className="price-area">
			              <span>USA</span> 
			              
			            </div>
						<ul className="pricing-list">
						<li><i className="fa fa-map-marker" />Georgia, Atlanta </li>
					
</ul>
<br/>
						 <br/>
						 <br/>
			 <br/>
			             <Link className="btn btn-base btn-radius w-100 " to="#"><i className="fa fa-phone" /><span>+(612)483-0111 </span></Link>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-6">
			        <div className="single-pricing-inner-wrap">
			          <div className="single-pricing-inner">
			            {/* <h6 className="title">STANDARD</h6> */}
			            <div className="price-area">
			              <span>EUROPE</span>
			            
			            </div>
			            <ul className="pricing-list">
			              <li><i className="fa fa-map-marker" />Duisburg </li>
           
			            </ul> 
						<br/>
			 <br/>
			 <br/>
			 <br/>
						<Link className="btn btn-base btn-radius w-100 " to="#"><i className="fa fa-phone" /><span>+4917634378700</span></Link>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-6">
			        <div className="single-pricing-inner-wrap">
			          <div className="single-pricing-inner">
			            {/* <h6 className="title">PLATINUM</h6> */}
			            <div className="price-area">
			              <span>INDIA </span>
			          
			            </div>
						<ul className="pricing-list">
						<li><i className="fa fa-map-marker" />No 5/635-a, PMS Complex, Namakkal Main Road, Kumaramangalam,<br/> Tiruchengode – 637205 

Namakkal district  ,

Tamilnadu</li>
</ul>
			            <Link className="btn btn-base btn-radius w-100 " to="#"><i className="fa fa-phone" /><span>+91 8807130715 </span></Link>
			          </div>
			        </div>
					
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default Pricing