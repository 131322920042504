import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Funfact extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="counter-area bg-gray">
			  <div className="container">
			    <div className="counter-area-inner pd-top-120 pd-bottom-120" style={{backgroundImage: 'url("'+publicUrl+'assets/img/other/1.png")'}}>
			      <div className="row">
			        <div className="col-lg-8 mb-5 mb-lg-0">
			          <div className="section-title mb-0">
			            <h6 className="sub-title right-line">Statistics</h6>
			            <h2 className="title">Our Vision </h2>
			            <p className="content pb-3">The thrust of Incliff Technologies is to provide current businesses with tailored software solutions that will make them to run efficiently and largely make them future ready. </p>
			            <div className="btn-counter bg-base mt-4">
			              <h3 className="left-val align-self-center"><span className="counter">1000</span>+</h3>
			              <div className="right-val align-self-center">
						  Hours of  <br /> training experience
			              </div>
			            </div>
			          </div>
			        </div>
			        <div className="col-lg-4 align-self-center">
			          <ul className="single-list-wrap">
			            <li className="single-list-inner style-box-bg">
			              <div className="media">
			                <div className="media-left">
			                  <img src={publicUrl+"assets/img/icon/1.png"} alt="img" />
			                </div>
			                <div className="media-body align-self-center">
			                  <h5><span className="counter">100</span>+</h5>
			                  <p>Years of cumulative experiences </p>
			                </div>
			              </div>
			            </li>
			            <li className="single-list-inner style-box-bg">
			              <div className="media">
			                <div className="media-left">
			                  <img src={publicUrl+"assets/img/icon/2.png"} alt="img" />
			                </div>
			                <div className="media-body align-self-center">
			                  <h5><span className="counter">5</span></h5>
			                  <p>Leadership team members</p>
			                </div>
			              </div>
			            </li>
			            <li className="single-list-inner style-box-bg">
			              <div className="media">
			                <div className="media-left">
			                  <img src={publicUrl+"assets/img/icon/3.png"} alt="img" />
			                </div>
			                <div className="media-body align-self-center">
			                  <h5><span className="counter">1000</span>+</h5>
			                  <p>Hours of training experience</p>
			                </div>
			              </div>
			            </li>
			          </ul>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default Funfact