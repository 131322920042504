import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

function BannerV3() {
	let publicUrl = process.env.PUBLIC_URL+'/'
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
		autoplaySpeed: 100,
        speed: 1200,
        arrows: false,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				
			  }
			},	
			{
			  breakpoint: 767,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				
			  }
			},
		]
		
    };

    return  (
	<>
	<Slider {...settings}>
	<div className="banner-area banner-area-3 bg-black go-top">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-5 col-md-10">
			        <div className="thumb b-animate-thumb">
			          <img src={publicUrl+"assets/img/banner/3.png"} alt="img" />
			        </div>
			      </div>
			      <div className="col-lg-7 align-self-center mt-5 mt-lg-0">
			        <div className="banner-inner style-white text-center text-lg-left">
			          <h6 className="b-animate-1 banner-btn">PLACE TO GROW</h6>
			          <h1 className="b-animate-2 title">We creating leaders for tomorrow</h1>
			          <Link className="btn btn-base b-animate-3 mr-sm-3 mr-2" to="/blog">Get A Quote</Link>
			          <Link className="btn btn-border-white b-animate-3" to="/blog">Read More</Link>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="banner-area banner-area-3 bg-black go-top">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-5 col-md-10">
			        <div className="thumb b-animate-thumb">
			          <img src={publicUrl+"assets/img/banner/3.png"} alt="img" />
			        </div>
			      </div>
			      <div className="col-lg-7 align-self-center mt-5 mt-lg-0">
			        <div className="banner-inner style-white text-center text-lg-left">
			          <h6 className="b-animate-1 banner-btn">PLACE TO GROW</h6>
			          <h1 className="b-animate-2 title">We creating leaders for tomorrow</h1>
			          <Link className="btn btn-base b-animate-3 mr-sm-3 mr-2" to="/blog">Get A Quote</Link>
			          <Link className="btn btn-border-white b-animate-3" to="/blog">Read More</Link>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			</Slider>
			</>
	)
        }


export default BannerV3