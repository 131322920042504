import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CourseDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="course-single-area pd-top-120 pd-bottom-90">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			      
			         
			       
			         
			          <div className="tab-content" >
			        
			              <div className="course-details-content">
			               
			               
			                <div id="accordion" className="accordion-area mt-4">
			                  <div className="card single-faq-inner style-header-bg">
			                    <div className="card-header" id="ff-five">
			                      <h5 className="mb-0">
			                        <button className="btn-link" data-toggle="collapse" data-target="#f-five" aria-expanded="true" aria-controls="f-five">
									Foundation Course
			                          <i className="fa fa-eye" />
			                        </button>
			                      </h5>
			                    </div>
			                    <div id="f-five" className="show collapse" aria-labelledby="ff-five" data-parent="#accordion">
			                      <div className="card-body">
			                      This course is designed by the founders based on the experience and learnings that they have gained during their years.<br />
								   When one finishes this course, they will gain confidence as they will enter the IT industry and get to know about the processes <br />in the software development life cycles.
								   <br />
								   <br />
								   ➜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SDLC and Development Methodologies (Waterfall and Agile) <br />
								   <br />
								   ➜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Software Configuration Management & Version Control (GIT) <br />
								   <br />
								   ➜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Programming Basics (Java) <br />
<br />
➜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Software Testing Concepts & Tools <br />
<br />
➜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Software Packaging & Deployments <br />
<br />
➜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cloud Computing Basics <br />
<br />
➜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Software Maintenance & Support <br />
<br />
➜&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Effective Communication <br />
			                      </div>
			                    </div>
			                  </div>
			                  <div className="card single-faq-inner style-header-bg">
			                    <div className="card-header" id="ff-six">
			                      <h5 className="mb-0">
			                        <button className="btn-link collapsed" data-toggle="collapse" data-target="#f-six" aria-expanded="true" aria-controls="f-six">
									Cloud Computing Course
			                          <i className="fa fa-eye" />
			                        </button>
			                      </h5>
			                    </div>
			                    <div id="f-six" className="collapse" aria-labelledby="ff-six" data-parent="#accordion">
			                      <div className="card-body">
								  This course is for whomever has finished the Foundation Course and is ready to learn advanced development with <br /> cloud integration. 
			                      </div>
			                    </div>
			                  </div>
							  
			                 
							
			           
			              
			                </div>
			              </div>
			            </div>
			      
			          </div>
			        </div>
			      </div>
			      
			
			  </div>
		

        }
}

export default CourseDetails