import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class EventPage extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="event-area pd-top-120 pd-bottom-120 go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6">
				  <Link to="/instructor-details">
			        <div className="media single-event-inner">
			          <div className="media-left date">
					  <img style={{width: '90%',}} src={publicUrl+"assets/img/icon/35.png"} alt="img" />
			          </div>
			          <div className="media-body details">
			            <ul className="blog-meta">
						<h6> <li><i className="fa fa-briefcase" /> 0 to 1 yrs</li></h6>
						<h6>  <li><i className="fa fa-map-marker" /> Chennai</li></h6>
			            </ul>
			            <h5><Link to="/instructor-details">Developer - Intern</Link></h5>
			            
			          </div>
			        </div>
					</Link>
			      </div>
			     
			     
			      <div className="col-lg-6">
				  <Link to="/instructor-details">
			        <div className="media single-event-inner">
			          <div className="media-left date">
					  <img style={{width: '90%',}} src={publicUrl+"assets/img/icon/37.png"} alt="img" />
			          </div>
			          <div className="media-body details">
			            <ul className="blog-meta">
			           <h6> <li><i className="fa fa-briefcase" /> 1 to 4 yrs</li></h6>
			           <h6>  <li><i className="fa fa-map-marker" /> Chennai</li></h6>
			            </ul>
						<h5><Link to="/instructor-details">Business Development Executive</Link></h5>
			            
			          </div>
			        </div>
					</Link>
			      </div>
			      
			  
			     
			    </div>
			  </div>
			</div>
        }
}

export default EventPage