import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ClientV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="client-area pd-top-100">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-12">
			        <div className="institute-slider owl-carousel">
			          <div className="item">
			            <img src={publicUrl+"assets/img/HP_logo.png"} alt="img" />
			          </div>
			          
			          <div className="item">
			            <img src={publicUrl+"assets/img/apple.png"} alt="img" />
			          </div>
					  <div className="item">
			            <img src={publicUrl+"assets/img/amex-logo.png"} alt="img" />
			          </div>
					  
			          <div className="item">
			            <img src={publicUrl+"assets/img/General-Motors-logo.png"} alt="img" />
			          </div>
					  <div className="item">
			            <img src={publicUrl+"assets/img/Cognizant-Logo.png"} alt="img" />
			          </div>
					  <div className="item">
			            <img src={publicUrl+"assets/img/tcs-logo.png"} alt="img" />
			          </div> 
					  <div className="item" >
			            <img src={publicUrl+"assets/img/infosys.png"} alt="img" />
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default ClientV2