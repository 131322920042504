
/* global google */
import React, { useState } from "react";
import { GoogleMap, InfoWindow, Marker,LoadScript } from "@react-google-maps/api";

const markers = [
  {
    id: 1,
    name: "United States",
    position: { lat:41.681408, lng: -72.794746}
  },
  {
    id: 2,
    name: "Europe",
    position: { lat: 53.0000, lng: 55.1668488 }
  },
  {
    id: 3,
    name: "India",
    position: { lat: 21.7679, lng:  78.8718 }
  },
  
];

function GMap() {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const google = window.google;
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return (
    <LoadScript
    googleMapsApiKey="AIzaSyAIqezs6nT9_u1SpcUMXRv86v9RQgQPS5E"
  >
    <GoogleMap 
  
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100vw", height: "100vh" }}
    >
      {markers.map(({ id, name, position }) => (
        <Marker
          key={id}
          position={position}
          onClick={() => handleActiveMarker(id)}
        >
        
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{name}</div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
    </LoadScript>
  );
}

export default GMap;

