import React ,{useState,useEffect} from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import Pricing from './section-components/pricing';
import Footer from './global-components/footer';
import GMAP from '../GMAP';
const GOOGLE_MAP_API_KEY = 'AIzaSyAIqezs6nT9_u1SpcUMXRv86v9RQgQPS5E';

const loadGoogleMapScript = (callback) => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  }
const PricingPage = () => {
    const [loadMap, setLoadMap] = useState(false);

    useEffect(() => {
      loadGoogleMapScript(() => {
        setLoadMap(true)
      });
    }, []);
    return <div>
        <Navbar />
        <PageHeader headertitle="Contact Us"  />
        <Pricing />
        
       
			  <GMAP/>
			<br/>
      <br/>
        <Footer />
    </div>
}

export default PricingPage

