import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer_v1 extends Component {

    componentDidMount() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
		<footer className="footer-area footer-area-2 bg-gray">
		  {/* <div className="footer-subscribe">
		    <div className="container">
		      <form className="footer-subscribe-inner">
		        <div className="row">
		          <div className="col-lg-5">
		            <div className="single-input-inner style-border-bottom">
		              <input type="text" placeholder="Your Full Name" />
		            </div>
		          </div>
		           <div className="col-lg-5">
		            <div className="single-input-inner style-border-bottom">
		              <input type="text" placeholder="Your Email Address" />
		            </div>
		          </div> 
		          <div className="col-lg-2">
		            <a className="btn btn-base" href="#">Subscribe</a>
		          </div>
		        </div>
		      </form>
		    </div>
		  </div> */}
		  <div className="footer-top">
		    <div className="container">
		      <div className="row">
			  <div className="col-lg-3 col-md-6">
			          <div className="widget widget_about text-center">
			            <a href="index.html"><img src={publicUrl+"assets/img/banner.jpg"} alt="img" /></a>
			            <div className="details">
			              <p>Incliff Technologies was founded by group of software technology experts who have decades of experiences in the IT field. Incliff Technologies is the right answer for all your software needs. </p>
			              <ul className="social-media">
			                <li><a href="https://www.facebook.com/"><i className="fa fa-facebook" /></a></li>
			                <li><a href="https://www.twitter.com/"><i className="fa fa-twitter" /></a></li>
			                <li><a href="https://www.instagram.com/"><i className="fa fa-instagram" /></a></li>
			                <li><a href="https://www.youtube.com/"><i className="fa fa-youtube" /></a></li>
			              </ul>
			            </div>
			          </div>
			        </div>
		        <div className="col-lg-3 col-md-6">
		          <div className="widget widget_contact">
		            <h4 className="widget-title">Contact Us</h4>
		            <ul className="details">
		              <li><i className="fa fa-map-marker" /> No 5/635-a, PMS Complex, Namakkal Main Road, Kumaramangalam, Tiruchengode – 637205 

Namakkal district  

Tamilnadu, India .</li>
		              <li><i className="fa fa-envelope" />info.inclifftech@gmail.com </li>
		              <li><i className="fa fa-phone" /> +91 8807130715 </li>
		            </ul>
		          </div>
		        </div>
		        <div className="col-lg-3 col-md-6">
		          <div className="widget widget_nav_menu">
		            <h4 className="widget-title">Solutions</h4>
		            <ul className="go-top">
		              <li><Link to="/contact">Supermarket Management</Link></li>
		              <li><Link to="/contact">Restaurant Management </Link></li>
		              <li><Link to="/contact">Product Development </Link></li>
		              <li><Link to="/contact">App Development </Link></li>
		              <li><Link to="/contact">Custom Software </Link></li>
		            </ul>
		          </div>
		        </div>
		        <div className="col-lg-3 col-md-6 go-top">
		          <div className="widget_nav_menu">
		            <h4 className="widget-title">Our Links</h4>
		            <ul className="go-top">
		              <li>
		            <Link to="/">Home</Link>
		                
		              </li>
					  <li>
		               <Link to="/about">About us</Link>
		               
		              </li>
					  <li>
		               <Link to="/contact">Solutions</Link>
		               
		              </li>
		              <li>
		               <Link to="/course-details">Courses</Link>
		               
		              </li>
					 
					  <li>
		               <Link to="/event">Careers</Link>
		               
		              </li>
					 
					  <li>
		               <Link to="/pricing">Contact Us</Link>
		               
		              </li>
		            </ul>
		          </div>
		        </div>
		      
		      </div>
		    </div>
		  </div>
		  <div className="footer-bottom">
		    <div className="container">
		      <div className="row">
		        <div className="col-lg-4 col-md-6 align-self-center">
		          <a href="index.html"><img src={publicUrl+"assets/img/footer.jpg"} alt="img" /></a>
		        </div>
		        <div className="col-lg-4  col-md-6 order-lg-12 text-md-right align-self-center">
		          <ul className="social-media mt-md-0 mt-3">
		            <li><a className="facebook" href="https://www.facebook.com/"><i className="fa fa-facebook" /></a></li>
		            <li><a className="twitter" href="https://www.twitter.com/"><i className="fa fa-twitter" /></a></li>
		            <li><a className="instagram" href="https://www.instagram.com/"><i className="fa fa-instagram" /></a></li>
		            <li><a className="youtube" href="https://www.youtube.com/"><i className="fa fa-youtube" /></a></li>
		            {/* <li><a className="pinterest" href="https://www.pinterest.com/solverwp/"><i className="fa fa-pinterest" /></a></li> */}
		          </ul>
		        </div>
		        <div className="col-lg-4 order-lg-8 text-lg-center align-self-center mt-lg-0 mt-3">
		          <p>Copyright &copy; {(new Date().getFullYear())} by Incliff Technologies</p>
				
		        </div>
		      </div>
		    </div>
		  </div>
		</footer>

        )
    }
}


export default Footer_v1