import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class InstructorDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="main-blog-area pd-top-120 pd-bottom-90">
			  <div className="container">
			    <div className="team-details-page">
			      <div className="row">
			     
			        <div className="col-lg-7 align-self-center mt-5 mt-lg-0">
			          <div className="details">
			            <h3>Web Developer</h3>
			            <span className="designation">FULL-TIME
(CHENNAI
)
2 TO 4 EXPERIENCE</span>
			            <ul className="social-media style-base pt-2 mb-4">
			              <li>
			                <a href="#"><i className="fa fa-facebook" aria-hidden="true" /></a>
			              </li>
			              <li>
			                <a href="#"><i className="fa fa-twitter" aria-hidden="true" /></a>
			              </li>
			              <li>
			                <a href="#"><i className="fa fa-instagram" aria-hidden="true" /></a>
			              </li>
			              <li>
			                <a href="#"><i className="fa fa-pinterest" aria-hidden="true" /></a>
			              </li>
			              <li>
			                <a href="#"><i className="fa fa-linkedin" aria-hidden="true" /></a>
			              </li>
			            </ul>
			            <span>Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz. Brick quiz whangs jumpy veldt</span> 

						<br/>
			            
						<span className="designation">Responsibilities</span>
						<p className="mt-4">
						◉  Be a great team player, experienced in working with agile teams. Ability to collaborate closely with developers, copywriters, and UX designers.<br/>
						◉  Create, improve and use wireframes, prototypes, style guides, user flows, and effectively communicate your interaction ideas using any of these methods.<br/>
						◉ Present and defend your design decisions. All your design decisions should be based on the overall design roadmap as well as your own design thinking and fundamental principles (i.e. color theory, visual weight, etc.) <br/>
						◉ Continually keep yourself and your design team updated with the latest changes in your industry’s standards. </p>
			          </div>
			        </div>
			      </div>
			      <div className="details-inner mt-4 pt-xl-3">
			        <span>Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz. Brick quiz whangs jumpy veldt</span>
			        <p className="mt-3">The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymph, for quick jigs vex! Fox nymphs grab quick-jived waltz. Brick quiz whangs jumpy veldt fox. Bright vixens jump; dozy fowl quack. Quick wafting zephyrs vex bold Jim. Quick zephyrs blow, vexing daft Jim. Sex-charged fop blew </p>
			      </div>
			    </div>
				<br/>
			    <Link className="btn btn-base b-animate-3 mr-sm-3 mr-2" to="/career">APPLY FOR THIS JOB</Link>
			  </div>
			</div>

        }
}

export default InstructorDetails